import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { selectSelectedAccountId } from '@mkp/account/state';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { WalletMapper, WalletResource } from '@mkp/shared/data-access';
import { walletApiActions } from '@mkp/wallet/actions';
import { creditManagementPageActions } from '@mkp/credit/actions';
import { concatLatestFrom } from '@ngrx/operators';

export const addWallet = createEffect(
  (actions$ = inject(Actions), store = inject(Store), walletResource = inject(WalletResource)) =>
    actions$.pipe(
      ofType(walletApiActions.addWallet),
      switchMap(({ wallet }) =>
        walletResource.add(wallet).pipe(
          concatLatestFrom(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
          map(([createdWallet, selectedAccountId]) =>
            WalletMapper.toViewModel(createdWallet, selectedAccountId)
          ),
          map((wallet) => walletApiActions.addWalletSuccess({ wallet })),
          catchError((error) => of(walletApiActions.addWalletFailure({ error })))
        )
      )
    ),
  { functional: true }
);

export const updateWallet = createEffect(
  (actions$ = inject(Actions), store = inject(Store), walletResource = inject(WalletResource)) =>
    actions$.pipe(
      ofType(walletApiActions.updateWallet),
      switchMap(({ walletId, wallet }) =>
        walletResource.update(walletId, wallet).pipe(
          concatLatestFrom(() => store.select(selectSelectedAccountId).pipe(filter(Boolean))),
          map(([updatedWallet, selectedAccountId]) =>
            WalletMapper.toViewModel(updatedWallet, selectedAccountId)
          ),
          map((updatedWallet) => walletApiActions.updateWalletSuccess({ wallet: updatedWallet })),
          catchError((error) => of(walletApiActions.updateWalletFailure({ error })))
        )
      )
    ),
  { functional: true }
);

export const loadWalletsByAccountId = createEffect(
  (actions$ = inject(Actions), walletResource = inject(WalletResource)) =>
    actions$.pipe(
      ofType(walletApiActions.loadWallets),
      switchMap(({ accountId }) =>
        walletResource
          .getWithQuery({
            filter: `walletAccesses.account.id==${accountId}`,
          })
          .pipe(
            map(({ _embedded: { results: wallets } }) =>
              WalletMapper.toViewModel(wallets, accountId)
            ),
            switchMap((wallets) => of(walletApiActions.loadWalletsSuccess({ wallets }))),
            catchError((error) => of(walletApiActions.loadWalletsFailure({ error })))
          )
      )
    ),
  { functional: true }
);

export const loadWallets = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(creditManagementPageActions.enter),
      switchMap(() =>
        store.select(selectSelectedAccountId).pipe(
          filter(Boolean),
          map((selectSelectedAccountId) =>
            walletApiActions.loadWallets({ accountId: selectSelectedAccountId })
          )
        )
      )
    ),
  { functional: true }
);
