import { Mention } from '@mkp/text-editor/feature-text-editor';

export enum AtsMentionsIds {
  FirstName = 'firstName',
  LastName = 'lastName',
  Platform = 'platform',
}

export const ATS_MENTIONS: Mention<AtsMentionsIds>[] = [
  { id: AtsMentionsIds.FirstName, text: 'First name' },
  { id: AtsMentionsIds.LastName, text: 'Last name' },
  { id: AtsMentionsIds.Platform, text: 'Platform' },
];
