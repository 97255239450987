import { environment } from '@env/environment';
import { Environment } from '@env/environment.model';
import { debugActions } from '@mkp/debug/actions';
import { objectKeys } from '@mkp/shared/util-format';
import { Action, ActionReducer, createFeature, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { getIsDebugActive, getShouldSyncDebug } from './debug.helper';

export enum FeatureFlags {
  // default debug option, it follows the requests
  // true for dev, qa
  // false for stage, prod
  default = 'default', // Tealium debugging
  routerTracingEnabled = 'routerTracingEnabled',
  // default false for all environments
  trackingLink = 'trackingLink',
  trackingView = 'trackingView',
  trackingClick = 'trackingClick',
  // Enables the invoice feature, this was adding because Abacus won't be ready for a while for migrated clients. (CDA-591)
  // default false for all environments
  isInvoiceEnabled = 'isInvoiceEnabled', // Enables the new ATS
  isNewVacancyPreviewUI = 'isNewPreviewUI',
  isGenerateAbandonedLinkEnabled = 'isGenerateAbandonedLinkEnabled',
  isNewAccountManagementEnabled = 'isNewAccountManagementEnabled',
  isNewCreditAndWalletManagementEnabled = 'isNewCreditAndWalletManagementEnabled',
  isNormalUserAccountManagementEnabled = 'isNormalUserAccountManagementEnabled',
  // trigger a silent update on the next navigation-end router event
  shouldTriggerSilentUpdate = 'shouldTriggerSilentUpdate',
  // feature flag to enable bulk actions
  isBulkActionsEnabledInATS = 'isBulkActionsEnabledInATS',
}

export interface DebugState {
  featureFlags: Record<FeatureFlags, boolean>;
  editMode: boolean;
}

export const getInitialState = (environment: Environment): DebugState => ({
  featureFlags: {
    [FeatureFlags.default]: getIsDebugActive(environment),
    [FeatureFlags.routerTracingEnabled]: false,
    [FeatureFlags.isInvoiceEnabled]: false,
    [FeatureFlags.trackingLink]: false,
    [FeatureFlags.trackingView]: false,
    [FeatureFlags.trackingClick]: false,
    [FeatureFlags.isNewVacancyPreviewUI]: false,
    [FeatureFlags.isGenerateAbandonedLinkEnabled]: false,
    [FeatureFlags.isNewAccountManagementEnabled]: false,
    [FeatureFlags.isNewCreditAndWalletManagementEnabled]: false,
    [FeatureFlags.isNormalUserAccountManagementEnabled]: false,
    [FeatureFlags.shouldTriggerSilentUpdate]: false,
    [FeatureFlags.isBulkActionsEnabledInATS]: false,
  },
  editMode: false,
});

const reducer = createReducer(
  getInitialState(environment),
  on(debugActions.toggleEditableContent, ({ editMode, ...state }) => ({
    ...state,
    editMode: !editMode,
  })),
  on(debugActions.toggleFeatureFlag, (state, { featureFlag, value }) => ({
    ...state,
    featureFlags: { ...state.featureFlags, [featureFlag]: value },
  }))
);

export const debugFeature = createFeature({ name: 'debugState', reducer });

// we want to reset shouldTriggerSilentUpdate on every page load, so it should not be synced in localstorage
const getKeysToSync = (): (keyof DebugState['featureFlags'])[] =>
  objectKeys(getInitialState(environment).featureFlags).filter(
    (key) => key !== FeatureFlags.shouldTriggerSilentUpdate
  );

export const debugSyncReducer: MetaReducer = (reducer: ActionReducer<DebugState, Action>) =>
  localStorageSync({
    keys: [{ [debugFeature.name]: [{ featureFlags: getKeysToSync() }] }],
    storageKeySerializer: (key) => `jobcloud.${key}`,
    rehydrate: true,
    syncCondition: () => getShouldSyncDebug(environment),
  })(reducer);

export const reduceFromInitialState = (featureFlags: DebugState['featureFlags']) =>
  Object.keys(getInitialState(environment).featureFlags).reduce(
    (acc, key) => ({
      ...acc,
      [key]: featureFlags[key as FeatureFlags],
    }),
    {} as DebugState['featureFlags']
  );
